import React from "react";
import { Input, Button, message } from "antd";
import { changePassword } from "../../api";

const PagePassChange = ({ user }) => {
  const [oldPass, setOldPass] = React.useState("");
  const [newPass, setNewPass] = React.useState("");
  const [newPass2, setNewPass2] = React.useState("");

  return (
    <div>
      <div className="flex flex-col space-y-4 w-1/4">
        <h3 className="text-xl">Şifre Değiştir</h3>
        <Input.Password
          placeholder="Eski Şifre"
          onChange={(e) => setOldPass(e.target.value)}
        />
        <Input.Password
          placeholder="Yeni Şifre"
          onChange={(e) => setNewPass(e.target.value)}
        />
        <Input.Password
          placeholder="Yeni Şifre Tekrar"
          onChange={(e) => setNewPass2(e.target.value)}
        />
        <Button
          type="primary"
          onClick={async () => {
            if (newPass.length < 6) {
              return message.error("Şifre en az 6 karakter olmalıdır.");
            }
            if (newPass === oldPass) {
              return message.error("Şifreniz eski şifreniz ile aynı olamaz.");
            }
            if (newPass !== newPass2) {
              return message.error("Yeni şifreler uyuşmuyor");
            }
            try {
              await changePassword({
                oldPassword: oldPass,
                newPassword: newPass,
              });
              message.success("Şifreniz başarıyla değiştirildi.");
            } catch (error) {
              message.error(error.message);
            }
          }}
        >
          Değiştir
        </Button>
      </div>
    </div>
  );
};

export default PagePassChange;
