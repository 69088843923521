import React from "react";
import { Spin } from "antd";

const DashboardInfoBox = ({ data, ...props }) => {
  if (data?.loading) {
    return (
      <div {...props}>
        <div className="w-full h-full flex items-center justify-center">
          <Spin />
        </div>
      </div>
    );
  }
  return (
    <div {...props}>
      <div className="flex flex-col space-y-4 p-2 items-center">
        <div className="flex items-center justify-center border-b-2 border-blue-400">
          <span className="text-xl font-semibold">{data?.title}</span>
        </div>

        <span className="text-2xl font-semibold">
          {data?.value} {data?.postfix}
        </span>
      </div>
    </div>
  );
};

export default DashboardInfoBox;
