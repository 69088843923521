import React from "react";
import { RiFileExcel2Line } from "react-icons/ri";
import { SiMicrosoftexcel } from "react-icons/si";
import { GrDocumentCsv } from "react-icons/gr";
import { FiFile } from "react-icons/fi";
import { AiFillMinusCircle } from "react-icons/ai";

export const FormFileList = ({ fileList, setFileList }) => {
  return (
    <div className="flex flex-wrap space-x-4">
      {fileList.map((file, index) => {
        if (file.type && file.name) {
          return (
            <div
              className="flex flex-col items-center space-y-1 py-4"
              key={index}
            >
              <span
                className="self-end -mb-1 cursor-pointer"
                onClick={() => {
                  setFileList(fileList.filter((f, i) => i !== index));
                }}
              >
                <AiFillMinusCircle className=" text-red-400 text-lg" />
              </span>
              {file.type.includes("spreadsheetml") && (
                <RiFileExcel2Line className="text-3xl text-green-800" />
              )}
              {file.type.includes("vnd.ms-excel") && (
                <SiMicrosoftexcel className="text-3xl text-green-800" />
              )}
              {file.type.includes("csv") && (
                <GrDocumentCsv className="text-3xl text-black" />
              )}
              {!(
                file.type.includes("csv") ||
                file.type.includes("vnd.ms-excel") ||
                file.type.includes("spreadsheetml")
              ) && <FiFile className="text-3xl text-black" />}
              <p className="text-center" style={{ maxWidth: 100 }}>
                {file.name}
              </p>
            </div>
          );
        } else {
          return <></>;
        }
      })}
    </div>
  );
};
