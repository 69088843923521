import { Routes, Route, useLocation, useNavigate } from "react-router-dom";

import React from "react";
import PageProduct from "./components/pages/PageProduct";
import PageCustomers from "./components/pages/PageCustomers";
import PageDashboard from "./components/pages/PageDashboard";
import PageUsers from "./components/pages/PageUsers";
import PageSettings from "./components/pages/PageSettings";
import { PageProductImport } from "./components/pages/PageProductImport";
import { PageCustomersImport } from "./components/pages/PageCustomersImport";
import { PageSalesCreate } from "./components/pages/PageSalesCreate";
import PageLogin from "./components/pages/PageLogin";
import AppLayout from "./components/Layout";
import { useAuth } from "./contexts/AuthContext";
import { Button, Spin } from "antd";
import PageUserUpdate from "./components/pages/PageUserUpdate";
import PageSale from "./components/pages/PageSale";
import PagePassChange from "./components/pages/PagePassChange";

const App = () => {
  let location = useLocation();
  const { loggedIn, loading, user, logout } = useAuth();
  let navigate = useNavigate();

  if (loading) {
    return (
      <div className="w-screen h-screen flex justify-center items-center">
        <Spin />
      </div>
    );
  }

  if (location.pathname === "/login" && loggedIn) {
    navigate("/");
  }

  if (location.pathname === "/login") {
    return <PageLogin />;
  }

  if (!loggedIn) {
    navigate("/login");
  }

  if (user && user.role === "Salesman") {
    return (
      <div className="w-screen h-screen flex justify-center items-center bg-slate-800">
        <div className="bg-white rounded-md p-4 flex flex-col space-y-4 items-center">
          <p>
            Uygulamayı indirmek için{" "}
            <a href="https://expo.dev/artifacts/eas/b1rbAxpzovUpvbBWsEg9j2.apk">
              tıklayınız
            </a>
            .
          </p>
          <Button
            onClick={() => {
              logout();
            }}
          >
            Çıkış Yap
          </Button>
        </div>
      </div>
    );
  }

  return (
    <AppLayout>
      <Routes>
        <Route path="/" element={<PageDashboard user={user} />} />
        <Route path="/products" element={<PageProduct />} />
        <Route path="/products/import" element={<PageProductImport />} />
        <Route path="/customers" element={<PageCustomers />} />
        <Route path="/customers/import" element={<PageCustomersImport />} />
        <Route path="/sales/create" element={<PageSalesCreate />} />
        <Route path="/sales" element={<PageSale />} />
        <Route path="/users/update/:id" element={<PageUserUpdate />} />
        <Route path="/users" element={<PageUsers />} />

        <Route
          path="/settings"
          element={<PageSettings userRole={user.role} />}
        />
        <Route
          path="/changepassword"
          element={<PagePassChange user={user} />}
        />
      </Routes>
    </AppLayout>
  );
};

export default App;
