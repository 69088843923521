import { useState, createContext, useContext, useEffect } from "react";
import { fetchMe, refreshToken } from "../api";
import { useNavigate, useLocation } from "react-router-dom";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  let location = useLocation();

  useEffect(() => {
    (async () => {
      try {
        const data = await refreshToken(localStorage.getItem("refresh-token"));
        localStorage.setItem("access-token", data.AccessToken);
        localStorage.setItem("refresh-token", data.RefreshToken);
        setLoggedIn(true);
        setUser(data.User);
        setLoading(false);
      } catch (error) {
        if (location !== "/login") {
          navigate("/login");
          setLoading(false);
        }
      }
    })();
  }, []);

  const login = (data) => {
    localStorage.setItem("access-token", data.AccessToken);
    localStorage.setItem("refresh-token", data.RefreshToken);
    setLoggedIn(true);
    setUser(data.User);
    setLoading(false);
  };

  const logout = () => {
    localStorage.removeItem("access-token");
    localStorage.removeItem("refresh-token");
    setLoggedIn(false);
  };

  const values = {
    loggedIn,
    user,
    login,
    logout,
    loading,
  };

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
