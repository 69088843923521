import React from "react";
import BreadCrumb from "../BreadCrumb";
import ProductTable from "../tables/ProductTable";

const PageProduct = () => (
  <>
    <BreadCrumb
      breadcrumb={[{ title: "App" }, { title: "Ürünler", href: "/products" }]}
    />
    <ProductTable />
  </>
);

export default PageProduct;
