import React from "react";
import DataTable from "../DataTable";
import { MdEdit, MdOutlineDeleteOutline } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { Popconfirm } from "antd";

const UserTable = ({ deleteMutation }) => {
  const navigate = useNavigate();

  const columns = [
    {
      title: "Kod",
      dataIndex: "code",
      key: "code",
      dataType: "string",
    },
    {
      title: "İsim",
      dataIndex: "name",
      key: "name",
      dataType: "string",
    },
    {
      title: "Eposta",
      dataIndex: "email",
      key: "email",
      dataType: "string",
    },
    {
      title: "Rol",
      dataIndex: "role",
      key: "role",
      dataType: "radio",
      options: [
        {
          label: "Admin",
          value: "Admin",
        },
        {
          label: "Bölge Müdürü",
          value: "RD",
        },
        {
          label: "Satış Müdürü",
          value: "SD",
        },
        {
          label: "Satış Temsilcisi",
          value: "Salesman",
        },
      ],
      render: (text, record) => {
        return text === "Admin"
          ? "Admin"
          : text === "RD"
          ? "Bölge Müdürü"
          : text === "SD"
          ? "Satış Müdürü"
          : text === "Salesman"
          ? "Satış Temsilcisi"
          : "";
      },
    },
    {
      title: "Markalar",
      dataIndex: "brands",
      key: "brands",
      dataType: "string",
      render: (text, record) => (
        <p>
          {record.brands?.map((i, index) => (
            <span key={index}>
              {index === 0 ? "" : ","}
              {i}
            </span>
          ))}
        </p>
      ),
    },
    {
      title: "Bölge",
      dataIndex: ["region"],
      key: "region",
      dataType: "dependent",
      render: (text, record) => <p>{record.Region?.name}</p>,
      search: {
        table: "regions",
        col: "name",
        op: "like",
        dataKey: "id",
      },
    },
    {
      title: "Aksiyonlar",
      dataType: "null",
      dataIndex: "id",
      render: (text, record) => (
        <div className="flex space-x-2">
          <span
            className="rounded-full bg-yellow-400 p-2 text-white flex items-center justify-center cursor-pointer hover:bg-yellow-300 transition-all"
            onClick={() => {
              navigate(`/users/update/${record.id}`);
            }}
          >
            <MdEdit />
          </span>
          <Popconfirm
            onConfirm={() => {
              deleteMutation.mutate(record.id);
            }}
            title="Bu kullanıcıyı silmek istediğinize emin misiniz?"
            okText="Evet"
            cancelText="Hayır"
            onCancel={() => {}}
          >
            <span className="rounded-full bg-red-400 p-2 text-white flex items-center justify-center cursor-pointer hover:bg-red-300 transition-all">
              <MdOutlineDeleteOutline />
            </span>
          </Popconfirm>
        </div>
      ),
    },
  ];

  return <DataTable columns={columns} table="users" />;
};
export default UserTable;
