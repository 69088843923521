import React from "react";
import DataTable from "../DataTable";
import { useAuth } from "../../contexts/AuthContext";
import { formatPrice } from "../../utils";

const ProductTable = () => {
  const { user } = useAuth();

  const columns = [
    {
      title: "Marka",
      dataIndex: "brand",
      key: "brand",
      dataType: "enum",
      options: [
        user?.brands?.includes("LG") && {
          label: "LG",
          value: "LG",
        },
        user?.brands?.includes("Tefal") && {
          label: "Tefal",
          value: "TEFAL",
        },
        user?.brands?.includes("Karcher") && {
          label: "Karcher",
          value: "KARCHER",
        },
      ],
    },
    {
      title: "Stok Kodu",
      dataIndex: "code",
      key: "code",
      dataType: "string",
    },
    {
      title: "Ürün Kodu",
      render: (text, record) => <a>{record.name.split("-")[0]}</a>,
    },
    {
      title: "Ürün İsmi",
      dataIndex: "name",
      key: "name",
      dataType: "string",
      render: (text) => <a>{text.split("-")[1]}</a>,
    },

    {
      title: "Fiyat",
      dataIndex: "price",
      key: "price",
      dataType: "float",
      render: (text) => <a>{formatPrice(text)} ₺</a>,
    },
    {
      title: "Stok",
      dataIndex: "quantity",
      key: "quantity",
      dataType: "integer",
    },
  ];
  return <DataTable columns={columns} table="products" />;
};
export default ProductTable;
