import React, { useState, useEffect } from "react";
import SearchBar from "./SearchBar";
import { Button, Input, InputNumber, Select, Switch, message } from "antd";
import SaleFormProducts from "./SaleFormProducts";
import { addOrder } from "../api";

import { updateCustomer } from "../api";
import { formatPrice } from "../utils";

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};

function toFixedIfNecessary(value, dp) {
  return +parseFloat(value).toFixed(dp);
}

const findTotal = (arr) => {
  return arr.reduce((total, item) => {
    return total + item.totalPrice * item.quantity;
  }, 0);
};

const { TextArea } = Input;

const SaleForm = () => {
  const [form, setForm] = useState({
    customer: null,
    products: [],
    discount: 0,
    discountType: "amount",
    saleType: "cash",
    note: "",
  });

  const [userMail, setUserMail] = useState("");
  const [userPhone, setUserPhone] = useState("");

  const [mailDisabled, setMailDisabled] = useState(true);
  const [phoneDisabled, setPhoneDisabled] = useState(true);

  const [mailLoading, setMailLoading] = useState(false);
  const [phoneLoading, setPhoneLoading] = useState(false);

  const submitHandler = async () => {
    if (!form.customer || !(form.products.length > 0)) {
      message.error({
        content: "Lütfen müşteri ve ürün seçimi yapınız.",
        key: "sale",
      });
    } else if (form.products.filter((item) => item.quantity <= 0).length > 0) {
      message.error({
        content: "Lütfen ürün miktarlarını kontrol ediniz.",
        key: "sale",
      });
    } else {
      message.loading({
        content: "Yükleniyor",
        key: "sale",
      });

      try {
        await addOrder({
          customer: form.customer.id,
          products: form.products.map((item) => {
            return {
              product: item.product.id,
              quantity: item.quantity,
              discountType: item.discountType,
              discount: item.discount,
              price: item.totalPrice,
            };
          }),
          discountType: form.discountType,
          discount: form.discount,
          saleType: form.saleType,
          note: form.note,
          total:
            form.discountType === "amount"
              ? findTotal(form.products) - form.discount
              : toFixedIfNecessary(
                  (findTotal(form.products) * (100 - form.discount)) / 100,
                  2,
                ),
        });

        message.success({
          content: "Satış Başarıyla Oluşturuldu",
          key: "sale",
        });

        setForm({
          customer: null,
          products: [],
          discount: 0,
          discountType: "amount",
          saleType: "cash",
          note: "",
        });
      } catch (error) {
        message.error({
          content: error.response.data.message,
          key: "sale",
        });
      }
    }
  };

  return (
    <div className="flex flex-col space-y-4">
      <div className="flex flex-col space-y-1">
        <span>Caari Seçimi</span>
        <SearchBar
          table="customers"
          col="name"
          op="like"
          onChange={(val) => {
            setForm({
              ...form,
              customer: val,
            });
            setUserMail(val?.email);
            setUserPhone(val?.phone);
          }}
          value={form.customer}
        />
      </div>

      {form.customer && (
        <div className="flex space-x-8">
          <div className="flex flex-col w-1/3">
            <span>Müşteri E-Posta</span>
            <div className="flex space-x-2">
              <Input
                value={userMail}
                placeholder={"joe@example.com"}
                onChange={(e) => {
                  setUserMail(e.target.value);
                  if (validateEmail(e.target.value)) {
                    setMailDisabled(false);
                  } else {
                    setMailDisabled(true);
                  }
                }}
              />
              <Button
                type="primary"
                disabled={mailDisabled}
                loading={mailLoading}
                onClick={async () => {
                  setMailLoading(true);
                  try {
                    await updateCustomer(form.customer.id, {
                      email: userMail,
                    });
                    setMailDisabled(true);
                    setMailLoading(false);
                    message.success({
                      content: "Müşteri e-postası başarıyla güncellendi",
                      key: "sale",
                    });
                  } catch (error) {
                    setMailDisabled(true);
                    setMailLoading(false);
                    message.error({
                      content: error.response.data.message,
                      key: "sale",
                    });
                  }
                }}
              >
                Güncelle
              </Button>
            </div>
          </div>
          <div className="flex flex-col w-1/3">
            <span>Müşteri Telefon</span>
            <div className="flex space-x-2">
              <Input
                value={userPhone}
                placeholder={"530 111 12 34"}
                onChange={(e) => {
                  if (e.target.value.length <= 10 && !isNaN(e.target.value)) {
                    setUserPhone(e.target.value);
                  }
                  if (e.target.value.length < 10) {
                    setPhoneDisabled(true);
                  } else {
                    setPhoneDisabled(false);
                  }
                }}
              />
              <Button
                type="primary"
                disabled={phoneDisabled}
                loading={phoneLoading}
                onClick={async () => {
                  setPhoneLoading(true);
                  try {
                    await updateCustomer(form.customer.id, {
                      phone: userPhone,
                    });
                    setPhoneDisabled(true);
                    setPhoneLoading(false);
                    message.success({
                      content: "Müşteri telefonu başarıyla güncellendi",
                      key: "sale",
                    });
                  } catch (error) {
                    setPhoneDisabled(true);
                    setPhoneLoading(false);
                    message.error({
                      content: error.response.data.message,
                      key: "sale",
                    });
                  }
                }}
              >
                Güncelle
              </Button>
            </div>
          </div>
        </div>
      )}

      <SaleFormProducts form={form} setForm={setForm} />

      <div className="flex items-end justify-between">
        <div className="flex flex-col space-y-1 w-1/6">
          <span>Sipariş Şekli</span>
          <Select
            value={form.saleType}
            onChange={(val) =>
              setForm({
                ...form,
                saleType: val,
              })
            }
          >
            <Select.Option value="cash">Nakit</Select.Option>
            <Select.Option value="debit_tek">
              Kredi Kartı (Tek Çekim)
            </Select.Option>
            <Select.Option
              value="debit_taksit
"
            >
              Kredi Kartı (Taksit)
            </Select.Option>
            <Select.Option value="vade">Vadeli</Select.Option>
          </Select>
        </div>
        <div className="flex space-x-1 justify-center py-2">
          <span>TL</span>
          <Switch
            className={
              form.discountType === "percent" ? "bg-green-500" : "bg-slate-500"
            }
            checked={form.discountType === "percent"}
            onChange={(val) =>
              setForm({
                ...form,
                discountType: val ? "percent" : "amount",
              })
            }
          />
          <span>Yüzde</span>
        </div>
        <div className="flex flex-col space-y-1">
          <span>İskonto</span>
          <InputNumber
            value={form.discount}
            onChange={(val) =>
              setForm({
                ...form,
                discount: val,
              })
            }
            className="w-36"
            min={0}
            max={
              form.discountType === "percent" ? 100 : findTotal(form.products)
            }
          />
        </div>
        <div className="flex flex-col space-y-1 w-48">
          <span>Toplam</span>
          <span className="pb-1.5 pt-1">
            {form.discountType === "amount"
              ? formatPrice(findTotal(form.products) - form.discount)
              : formatPrice(
                  (findTotal(form.products) * (100 - form.discount)) / 100,
                )}
            ₺
          </span>
        </div>
      </div>
      <div className="flex flex-col space-y-1">
        <span>Sipariş Notu</span>
        <TextArea
          placeholder="textarea with clear icon"
          allowClear
          value={form.note}
          onChange={(e) =>
            setForm({
              ...form,
              note: e.target.value,
            })
          }
        />
      </div>
      <Button
        type="primary"
        block
        className="bg-blue-400"
        onClick={() => submitHandler()}
      >
        Siparişi Oluştur
      </Button>
    </div>
  );
};

export default SaleForm;
