import { Button, message, Form } from "antd";
import React, { useState } from "react";
import BreadCrumb from "../BreadCrumb";
import CreateUser from "../forms/CreateUser";
import UserTable from "../tables/UserTable";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { removeUser, updateUser, userRegister } from "../../api";

const PageUsers = () => {
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  // Access the client
  const queryClient = useQueryClient();

  // Mutations
  const createMutation = useMutation(userRegister, {
    onSuccess: async () => {
      // Invalidate and refetch
      await queryClient.invalidateQueries(["users"]);
      setVisible(false);
      setLoading(false);
      message.success("Kullanıcı Başarıyla Oluşturuldu");
      form.resetFields();
    },
    onError: () => {
      setLoading(false);
      message.error("Kullanıcı Oluştururken Sorun Oluştu");
    },
  });

  const deleteMutation = useMutation(removeUser, {
    onSuccess: async () => {
      // Invalidate and refetch
      await queryClient.invalidateQueries(["users"]);
      message.success("Kullanıcı Başarıyla Kaldırıldı");
    },
  });

  const updateMutation = useMutation(updateUser, {
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries(["users"]);
      message.success("Kullanıcı Güncellendi");
    },
  });

  return (
    <>
      <div className="flex justify-between">
        <BreadCrumb
          breadcrumb={[
            { title: "App" },
            { title: "Kullanıcılar", href: "/users" },
          ]}
        />
        <Button
          type="primary"
          className=" bg-blue-400"
          size="small"
          onClick={() => setVisible(true)}
        >
          Kullancı Ekle
        </Button>
      </div>
      <UserTable deleteMutation={deleteMutation} />
      <CreateUser
        visible={visible}
        loading={loading}
        setVisible={setVisible}
        onFinish={(val) => {
          setLoading(true);
          createMutation.mutate(val);
        }}
        onCancel={() => {
          form.resetFields();
          setVisible(false);
        }}
        form={form}
      />
    </>
  );
};

export default PageUsers;
