import React from "react";
import BreadCrumb from "../BreadCrumb";
import SalesTable from "../tables/SalesTable";

const PageSale = () => (
  <>
    <BreadCrumb
      breadcrumb={[{ title: "App" }, { title: "Siparişler", href: "/sales" }]}
    />
    <SalesTable />
  </>
);

export default PageSale;
