import React from "react";
import BreadCrumb from "../BreadCrumb";
import UpdateUser from "../forms/UpdateUser";
import { useParams } from "react-router-dom";
import { fetchUser } from "../../api";
import { useQuery } from "@tanstack/react-query";
import { Button, Spin } from "antd";
import { Link } from "react-router-dom";

const PageUserUpdate = () => {
  let { id } = useParams();

  const { isLoading, data } = useQuery(["users", id], () => fetchUser(id));

  if (isLoading) {
    <Spin />;
  }

  return (
    <>
      <div className="flex justify-between items-end">
        <BreadCrumb
          breadcrumb={[
            { title: "App" },
            { title: "Kullanıcılar", href: "/users" },
            { title: data?.name, href: `/users/update/${data?.id}` },
          ]}
        />
        <Link to={"/users"}>
          <Button type="primary" className="my-2">
            Geri Dön
          </Button>
        </Link>
      </div>
      <div className=" bg-white p-4">
        <UpdateUser values={data} />
      </div>
    </>
  );
};

export default PageUserUpdate;
