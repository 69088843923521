import React, { useState, useEffect } from "react";
import { Input, Select, InputNumber } from "antd";
import SearchBar from "./SearchBar";

const { Option } = Select;

const PreComponent = ({ children, width, isActive, setActive }) => {
  return (
    <span
      children={<p>{children}</p>}
      style={{
        width: width,
        color: isActive ? "white" : "black",
        backgroundColor: isActive ? "rgb(64,145,247)" : "rgb(207,213,220)",
        cursor: "pointer",
        border: "1px solid #d9d9d9",
        borderRight: "none",
        lineHeight: "30px",
        textAlign: "center",
        transition: "all 0.3s ease",
      }}
      onClick={() => {
        setActive(!isActive);
      }}
    />
  );
};

const FilterItem = (props) => {
  const { dataType, column, value, onChange, options, search } = props;
  const [double, setDouble] = useState(value.op === "bt");

  const [isActive, setActive] = useState(value.isActive);
  const [itemValue, setItemValue] = useState(value.value);
  const [op, setOp] = useState(value.op);

  useEffect(() => {
    onChange({ isActive: isActive, value: itemValue, op: op });
  }, [itemValue, op, isActive]);

  useEffect(() => {
    setActive(value.isActive);
    setItemValue(value.value);
    setOp(value.op);
    setDouble(value.op === "bt");
  }, [value]);

  if (dataType === "string") {
    return (
      <Input.Group compact {...props}>
        <PreComponent
          width="25%"
          children={column}
          isActive={isActive}
          setActive={setActive}
        />
        <Input
          style={{
            width: "50%",
          }}
          placeholder="Ara..."
          value={itemValue}
          onChange={(e) => {
            setItemValue(e.target.value);
          }}
        />
        <Select
          value={op}
          style={{ width: "25%" }}
          onSelect={(op) => setOp(op)}
        >
          <Option value="like">Arama</Option>
          <Option value="sw">Başta</Option>
          <Option value="same">Eşit</Option>
        </Select>

        {/* <InputNumber /> */}
      </Input.Group>
    );
  }
  if (dataType === "integer") {
    return (
      <Input.Group compact {...props}>
        <PreComponent
          width={double ? "20%" : "30%"}
          children={column}
          isActive={isActive}
          setActive={setActive}
        />
        <InputNumber
          style={{
            width: double ? "25%" : "40%",
          }}
          placeholder={0}
          value={double ? itemValue[0] : itemValue}
          onChange={(val) => {
            if (double) {
              setItemValue([val, itemValue[1]]);
            } else {
              setItemValue(val);
            }
          }}
        />
        {double ? (
          <InputNumber
            style={{
              width: "25%",
            }}
            value={itemValue[1]}
            placeholder={0}
            onChange={(val) => {
              setItemValue([itemValue[0], val]);
            }}
          />
        ) : null}
        <Select
          value={op}
          style={{ width: "30%" }}
          onChange={(val) => {
            if (val === "bt") setDouble(true);
            if (val !== "bt") setDouble(false);
            setOp(val);
          }}
        >
          <Option value="eq">Eşit</Option>
          <Option value="gt">Büyük</Option>
          <Option value="lt">Küçük</Option>
          <Option value="gte">Büyük Eşit</Option>
          <Option value="lte">Küçük Eşit</Option>
          <Option value="bt">Arasında</Option>
        </Select>

        {/* <InputNumber /> */}
      </Input.Group>
    );
  }
  if (dataType === "float") {
    return (
      <Input.Group compact {...props} className="flex-nowrap">
        <PreComponent
          width={double ? "20%" : "30%"}
          children={column}
          isActive={isActive}
          setActive={setActive}
        />
        <InputNumber
          style={{
            width: double ? "25%" : "40%",
          }}
          placeholder={0}
          value={double ? itemValue[0] : itemValue}
          onChange={(val) => {
            if (double) {
              setItemValue([val, itemValue[1]]);
            } else {
              setItemValue(val);
            }
          }}
          step={0.1}
          className="doubleOut"
        />
        {double ? (
          <InputNumber
            style={{
              width: "25%",
            }}
            value={itemValue[1]}
            placeholder={0}
            onChange={(val) => {
              setItemValue([itemValue[0], val]);
            }}
            step={0.1}
            className="doubleIn"
          />
        ) : null}
        <Select
          value={op}
          style={{ width: "30%" }}
          onChange={(val) => {
            if (val === "bt") setDouble(true);
            if (val !== "bt") setDouble(false);
            setOp(val);
          }}
        >
          <Option value="eq">Eşit</Option>
          <Option value="gt">Büyük</Option>
          <Option value="lt">Küçük</Option>
          <Option value="gte">Büyük Eşit</Option>
          <Option value="lte">Küçük Eşit</Option>
          <Option value="bt">Arasında</Option>
        </Select>

        {/* <InputNumber /> */}
      </Input.Group>
    );
  }
  if (dataType === "enum") {
    return (
      <Input.Group compact {...props}>
        <PreComponent
          width="25%"
          children={column}
          isActive={isActive}
          setActive={setActive}
        />
        <Select
          style={{ width: "75%" }}
          value={itemValue}
          onChange={(val) => {
            setItemValue(val);
          }}
        >
          {options?.map((option) =>
            option?.label ? (
              <Option value={option?.value} key={option?.value}>
                {option?.label}
              </Option>
            ) : null,
          )}
        </Select>

        {/* <InputNumber /> */}
      </Input.Group>
    );
  }
  if (dataType === "dependent") {
    return (
      <Input.Group compact {...props}>
        <PreComponent
          width="25%"
          children={column}
          isActive={isActive}
          setActive={setActive}
        />

        <SearchBar
          table={search.table}
          col={search.col}
          op={search.op}
          onChange={(val) => {
            setItemValue(val ? val[search.dataKey] : null);
          }}
          style={{ width: "75%" }}
        />

        {/* <InputNumber /> */}
      </Input.Group>
    );
  }
  return null;
};

export default FilterItem;
