import React from "react";
import { Select } from "antd";
import { Spin } from "antd";
import { formatPrice } from "../utils";

const { Option } = Select;

const DashboardDifferentBox = ({ data, ...props }) => {
  const [period, setPeriod] = React.useState("weekly");
  if (data?.loading) {
    return (
      <div {...props}>
        <div className="w-full h-full flex items-center justify-center">
          <Spin />
        </div>
      </div>
    );
  }
  return (
    <div {...props}>
      <div className="flex flex-col p-2 space-y-4">
        <div className="flex items-center justify-between border-b-2 border-blue-400">
          <span className="text-xl font-semibold">{data?.title}</span>
          <Select
            defaultValue="weekly"
            size="small"
            style={{ width: 100 }}
            onChange={(val) => setPeriod(val)}
          >
            <Option value="daily" key={"daily"}>
              Bu Gün
            </Option>
            <Option value="weekly" key={"weekly"}>
              Bu Hafta
            </Option>
            <Option value="monthly" key={"monthly"}>
              Bu Ay
            </Option>
          </Select>
        </div>
        <div className="flex justify-between items-center">
          <span className="text-3xl font-thin">
            {data.isPrice
              ? formatPrice(data?.value[period].this)
              : data?.value[period].this}{" "}
            {data?.sufix}
          </span>
          <span className="font-semibold text-gray-600">
            <span
              className={
                data?.value[period].this - data?.value[period].last > 0
                  ? "bg-green-500 text-white rounded-full py-1 px-2"
                  : "bg-red-500 text-white rounded-full py-1 px-2"
              }
            >
              %{" "}
              {(
                (Math.abs(data?.value[period].this - data?.value[period].last) /
                  data?.value[period].this) *
                100
              ).toFixed(0)}
            </span>
          </span>
        </div>
        <div className="flex items-center justify-between">
          <span className="text-xs font-semibold text-gray-600">
            <span>
              {period === "daily"
                ? "Dün"
                : period === "weekly"
                ? "Geçen hafta"
                : "Geçen ay"}{" "}
              {data.isPrice
                ? formatPrice(data?.value[period].last)
                : data?.value[period].last}{" "}
              {data?.sufix}
            </span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default DashboardDifferentBox;
