import { InputNumber, Switch } from "antd";
import React, { useEffect, useState } from "react";
import { AiFillMinusCircle } from "react-icons/ai";
import SearchBar from "./SearchBar";
import { formatPrice } from "../utils";

function toFixedIfNecessary(value, dp) {
  return +parseFloat(value).toFixed(dp);
}

const SaleFormProduct = (props) => {
  const { onChange, onDelete, value } = props;

  const [productData, setProductData] = useState(value);

  useEffect(() => {
    onChange({
      ...productData,
      totalPrice:
        productData.discountType === "percent"
          ? toFixedIfNecessary(
              ((100 - productData?.discount) / 100) *
                productData.product?.price,
              2,
            )
          : productData.product?.price - productData?.discount,
    });
  }, [productData]);

  return (
    <div className="flex space-x-4 items-end">
      <div className="flex flex-col space-y-1 flex-grow">
        <span>Ürün Seçimi</span>
        <SearchBar
          table="products"
          col="name"
          op="like"
          onChange={(val) => {
            setProductData({
              ...productData,
              product: val,
            });
          }}
        />
      </div>
      <div className="flex flex-col space-y-1">
        <span>Fiyat</span>
        <span className="pb-1.5 pt-1">
          {productData.product?.price
            ? formatPrice(productData.product.price)
            : "NaN"}{" "}
          ₺
        </span>
      </div>
      <div className="flex flex-col space-y-1">
        <span>Stok</span>
        <span className="pb-1.5 pt-1">
          {productData.product?.quantity
            ? productData.product.quantity - productData.product.reservedStock
            : "NaN"}
        </span>
      </div>
      <div className="flex flex-col space-y-1">
        <span>Miktar</span>
        <InputNumber
          value={productData?.quantity}
          onChange={(val) =>
            setProductData({
              ...productData,
              quantity: val,
            })
          }
          max={
            productData.product?.quantity
              ? productData.product.quantity - productData.product.reservedStock
              : 0
          }
          min={productData.product?.quantity ? 1 : 0}
        />
      </div>
      {/*
      <div className="flex flex-col space-y-1">
        <span>Ara Toplam</span>
        <span className="pb-1.5 pt-1">
          {productData.product?.price * productData?.quantity} ₺
        </span>
      </div>
      <div className="flex space-x-1 justify-center py-2">
        <span>TL</span>
        <Switch
          className={
            productData.discountType === "percent"
              ? "bg-green-500"
              : "bg-slate-500"
          }
          checked={productData.discountType === "percent"}
          onChange={(val) =>
            setProductData({
              ...productData,
              discountType: val ? "percent" : "amount",
            })
          }
        />
        <span>Yüzde</span>
      </div>
      <div className="flex flex-col space-y-1">
        <span>İskonto</span>
        <InputNumber
          value={productData?.discount}
          onChange={(val) => {
            setProductData({
              ...productData,
              discount: val,
            });
          }}
          min={0}
          max={
            productData.discountType === "percent"
              ? 100
              : productData.product?.price * productData?.quantity
          }
        />
      </div> */}

      <div className="flex flex-col space-y-1 w-20">
        <span>Toplam</span>
        <span className="pb-1.5 pt-1">
          {productData.discountType === "percent"
            ? formatPrice(
                ((100 - productData?.discount) / 100) *
                  productData.product?.price *
                  productData?.quantity,
              )
            : formatPrice(
                productData.product?.price * productData?.quantity -
                  productData?.discount,
              )}{" "}
          ₺
        </span>
      </div>
      <span className="py-2">
        <AiFillMinusCircle
          className="text-red-500 text-lg cursor-pointer"
          onClick={() => onDelete(productData.uid)}
        />
      </span>
    </div>
  );
};

export default SaleFormProduct;
