import React from "react";
import { Button, Popconfirm, Input } from "antd";

const { TextArea } = Input;

const OrderResponse = ({ approve, reject, id, status }) => {
  const [note, setNote] = React.useState("");
  return (
    <div>
      <div className="flex flex-col border-4">
        <TextArea
          rows={2}
          placeholder="Notunuz var ise ekleyebilirsiniz..."
          value={note}
          onChange={(e) => setNote(e.target.value)}
        />
        <div className="flex">
          {status === "pending" && (
            <Popconfirm
              title="Sipariş onaylandığında ürünler stoktan düşülecektir. Onaylıyor musunuz?"
              onConfirm={() => approve({ id, note })}
              onCancel={() => {}}
              okText="Onayla"
              cancelText="İptal"
            >
              <Button type="primary" block className=" bg-blue-400">
                Siparişi Onayla
              </Button>
            </Popconfirm>
          )}
          <Popconfirm
            title="Silme işlemi geri alınamaz. Onaylıyor musunuz?"
            onConfirm={() => reject({ id, note })}
            onCancel={() => {}}
            okText="Onayla"
            cancelText="İptal"
          >
            <Button type="primary" block danger>
              Siparişi İptal Et
            </Button>
          </Popconfirm>
        </div>
      </div>
    </div>
  );
};

export default OrderResponse;
