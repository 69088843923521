import { Select, Spin } from "antd";
import React from "react";
import { useQuery } from "@tanstack/react-query";
import { getRegions } from "../../api";

const { Option } = Select;

const RegionSelect = ({ onChange, value }) => {
  // Queries
  const { isLoading, data } = useQuery(["regions"], getRegions);

  if (isLoading) {
    return <Spin />;
  }

  return (
    <Select
      placeholder="Lütfen Seçim Yapınız"
      style={{ width: "100%" }}
      onChange={onChange}
      value={value}
    >
      {data.items.map((region) => (
        <Option key={region.id} value={region.id}>
          {region.name}
        </Option>
      ))}
    </Select>
  );
};

export default RegionSelect;
