import { useNavigate, useLocation } from "react-router-dom";

import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";

import {
  BiBarcodeReader,
  BiUser,
  BiChevronDown,
  BiNotification,
} from "react-icons/bi";
import {
  FcSettings,
  FcPortraitMode,
  FcStatistics,
  FcConferenceCall,
  FcDataSheet,
  FcAddDatabase,
  FcMoneyTransfer,
  FcShop,
} from "react-icons/fc";
import { Avatar, Dropdown, Layout, Menu } from "antd";

import React, { useState } from "react";
import { useAuth } from "../contexts/AuthContext";

const { Header, Sider, Content, Footer } = Layout;

const UserDropdownMenu = ({ logout }) => {
  const navigate = useNavigate();
  return (
    <Menu
      items={[
        {
          label: (
            <span
              onClick={() => {
                navigate("/changepassword");
              }}
            >
              Şifre Değiştir
            </span>
          ),
          key: "1",
        },
        {
          type: "divider",
        },
        {
          label: (
            <a
              href="https://expo.dev/artifacts/eas/b1rbAxpzovUpvbBWsEg9j2.apk"
              className="text-black"
            >
              Uygulamayı Indir
            </a>
          ),
        },
        {
          type: "divider",
        },
        {
          label: "Çıkış Yap",
          key: "3",
          onClick: logout,
        },
      ]}
    />
  );
};

const AppLayout = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { user, logout } = useAuth();

  return (
    <Layout>
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <div className="logo text-white text-2xl pl-6 py-4 border-b border-slate-500">
          SVS
        </div>
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={[location.pathname]}
          items={[
            {
              key: "/",
              icon: <FcStatistics />,
              label: "Genel Bakış",
              onClick: () => {
                navigate("/");
              },
            },
            {
              label: "Ürün İşlemleri",
              key: "products",
              icon: <BiBarcodeReader className=" text-red-500" />,
              children: [
                {
                  label: "Listele",
                  key: "/products",
                  icon: <FcDataSheet />,
                  onClick: () => {
                    navigate("/products");
                  },
                },
                user.role === "Admin" && {
                  label: "İçe Aktar",
                  key: "/products/import",
                  icon: <FcAddDatabase />,
                  onClick: () => {
                    navigate("/products/import");
                  },
                },
              ],
            },
            {
              label: "Cari İşlemleri",
              key: "customers",
              icon: <FcConferenceCall />,
              children: [
                {
                  label: "Listele",
                  key: "/customers",
                  icon: <FcDataSheet />,
                  onClick: () => {
                    navigate("/customers");
                  },
                },
                user.role === "Admin" && {
                  label: "İçe Aktar",
                  key: "/customers/import",
                  icon: <FcAddDatabase />,
                  onClick: () => {
                    navigate("/customers/import");
                  },
                },
              ],
            },
            {
              label: "Satış İşlemleri",
              key: "sells",
              icon: <FcShop />,
              children: [
                {
                  label: "Satış Ekle",
                  key: "/sales/create",
                  icon: <FcMoneyTransfer />,
                  onClick: () => {
                    navigate("/sales/create");
                  },
                },
                {
                  label: "Listele",
                  key: "/sales",
                  icon: <FcDataSheet />,
                  onClick: () => {
                    navigate("/sales");
                  },
                },
              ],
            },
            user.role === "Admin" && {
              key: "/users",
              icon: <FcPortraitMode />,
              label: "Kullanıcı İşlemleri",
              onClick: () => {
                navigate("/users");
              },
            },
            user.role === "Admin" && {
              key: "/settings",
              icon: <FcSettings />,
              label: "Ayarlar",
              onClick: () => {
                navigate("/settings");
              },
            },
          ]}
        />
      </Sider>
      <Layout
        className="site-layout"
        style={{ marginLeft: collapsed ? 80 : 200 }}
      >
        <Header
          className="bg-slate-300 px-4"
          style={{
            position: "fixed",
            zIndex: 1,
            width: "100%",
            paddingRight: collapsed ? 96 : 216,
          }}
        >
          <div className="w-100 flex items-center justify-between">
            <span>
              {React.createElement(
                collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                {
                  className: "trigger",
                  onClick: () => setCollapsed(!collapsed),
                },
              )}
            </span>
            <div className="flex space-x-4 items-center">
              <span>
                <BiNotification className=" text-xl " />
              </span>

              <Dropdown
                overlay={<UserDropdownMenu logout={logout} />}
                trigger={["click"]}
              >
                <div className="flex items-center space-x-2 cursor-pointer hover:bg-slate-200 px-4 transition-all">
                  <Avatar size={24} icon={<BiUser size={24} />} />
                  <p className="capitalize">{user.name}</p>
                  <BiChevronDown />
                </div>
              </Dropdown>
            </div>
          </div>
        </Header>
        <Content
          className="site-layout-background"
          style={{
            margin: "80px 16px 16px 16px",
            overflow: "initial",
          }}
        >
          {children}
        </Content>
        <Footer
          style={{
            textAlign: "center",
          }}
        >
          SVS ©2022 All Rights Reserved
        </Footer>
      </Layout>
    </Layout>
  );
};

export default AppLayout;
