import React from "react";
import CustomerTable from "../tables/CustomerTable";
import BreadCrumb from "../BreadCrumb";

const PageCustomers = () => (
  <>
    <BreadCrumb
      breadcrumb={[{ title: "App" }, { title: "Cariler", href: "/customers" }]}
    />
    <CustomerTable />
  </>
);

export default PageCustomers;
