import React, { useState } from "react";
import { Collapse, Button } from "antd";
import FilterItem from "./FilterItem";

const { Panel } = Collapse;

const FilterGroup = (props) => {
  const filterToValue = (filter) => {
    const v = {};
    filter.forEach((f) => {
      v[f.key] = {
        value: f.value,
        op: f.op,
        isActive: true,
      };
    });
    return v;
  };

  const [value, setValue] = useState(filterToValue(props?.filters));

  return (
    <Collapse className="w-full" defaultActiveKey={"1"}>
      <Panel header="Filtreler" key="1">
        <div className="grid grid-cols-3 gap-4">
          {props.columns.map((column) => (
            <FilterItem
              dataType={column.dataType}
              column={column.title}
              key={column.dataIndex}
              options={column.options}
              search={column.search}
              className="w-full"
              value={
                value[column.dataIndex] || {
                  value:
                    column.dataType === "enum" ? column?.options[0]?.value : "",
                  op: column.defaultOp
                    ? column.defaultOp
                    : column.dataType === "integer" ||
                      column.dataType === "float" ||
                      column.dataType === "dependent"
                    ? "eq"
                    : column.dataType === "enum" &&
                      typeof column.options[0].value === "string"
                    ? "same"
                    : column.dataType === "enum" &&
                      typeof column.options[0].value === "number"
                    ? "eq"
                    : "like",
                  isActive: false,
                }
              }
              onChange={(val) => {
                setValue({
                  ...value,
                  [column.dataIndex]: val,
                });
              }}
            />
          ))}
          <div className="flex space-x-4">
            <Button
              type="link"
              onClick={() => {
                setValue({});
                props.onSubmit({});
              }}
            >
              Filtreleri Temizle
            </Button>
            <Button
              type="primary"
              className="bg-blue-400"
              onClick={() => {
                props.onSubmit(value);
              }}
            >
              Filtrele
            </Button>
          </div>
        </div>
      </Panel>
    </Collapse>
  );
};

export default FilterGroup;
