import React from "react";
import BreadCrumb from "../BreadCrumb";
import ImportForm from "../ImportForm";

export const PageCustomersImport = () => {
  return (
    <>
      <BreadCrumb
        breadcrumb={[
          { title: "App" },
          { title: "Cariler", href: "/customers" },
          { title: "Carileri İçe Aktar" },
        ]}
      />
      <ImportForm table="customers" />
    </>
  );
};
