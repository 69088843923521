import React from "react";
import DataTable from "../DataTable";
import moment from "moment";
import "moment/locale/tr";
import { BsFillCircleFill } from "react-icons/bs";
import { message, Table, Checkbox, Button } from "antd";
import {
  approveOrder,
  exportOrders,
  exportOrdersToMicro,
  exportedOrders,
  rejectOrder,
} from "../../api";
import OrderResponse from "../OrderResponse";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAuth } from "../../contexts/AuthContext";
import fileDownload from "js-file-download";
import { formatPrice } from "../../utils";

const downloadFile = (data, fileName) => {
  data = JSON.stringify(data);
  fileDownload(data, fileName, "application/json;charset=utf-8");
};

const approveOrderHandler = async (i) => {
  message.loading({ content: "İşlem yapılıyor...", key: "approveOrder" });
  try {
    const response = await approveOrder(i.id, i.note);
    message.success({ content: response.message, key: "approveOrder" });
  } catch (error) {
    message.error({
      content: error.response.data.message,
      key: "approveOrder",
    });
  }
};

const rejectOrderHandler = async (i) => {
  message.loading({ content: "İşlem yapılıyor...", key: "rejectOrder" });

  try {
    const response = await rejectOrder(i.id, i.note);
    message.success({ content: response.message, key: "rejectOrder" });
  } catch (error) {
    message.error({
      content: error.response.data.message,
      key: "rejectOrder",
    });
  }
};

const exportOrdersHandler = async (arr) => {
  message.loading({ content: "İşlem yapılıyor...", key: "exportOrders" });

  try {
    const response = await exportOrders(arr);
    message.loading({
      content: "Veriler Çekildi Aktarılıyor",
      key: "exportOrders",
    });

    const eexpt = await exportOrdersToMicro(response.endpoint, response.data);

    if (eexpt === "Success") {
      message.loading({
        content: "Aktarım tamamlandı db ye bilgi veriliyor",
        key: "exportOrders",
      });

      await exportedOrders(response.data.map((i) => i.orderID));

      message.success({
        content: "Aktarım Başarıyla Tamamlandı",
        key: "exportOrders",
      });
    }
  } catch (error) {
    message.error({
      content: "Aktarım Başarısız",
      key: "exportOrders",
    });
  }
};

const setExportedHandler = async (arr) => {
  message.loading({ content: "İşlem yapılıyor...", key: "exportOrders" });

  try {
    message.loading({
      content: "Veriler Düzenleniyor",
      key: "setExported",
    });

    await exportedOrders(arr);

    message.success({
      content: "Aktarım Başarıyla Tamamlandı",
      key: "setExported",
    });
  } catch (error) {
    message.error({
      content: "Aktarım Başarısız",
      key: "setExported",
    });
  }
};

const SalesTable = () => {
  const { user } = useAuth();

  const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);

  const queryClient = useQueryClient();

  const approveMutation = useMutation(approveOrderHandler, {
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries(["orders"]);
    },
  });

  const rejectMutation = useMutation(rejectOrderHandler, {
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries(["orders"]);
    },
  });

  const exportMutation = useMutation(exportOrdersHandler, {
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries(["orders"]);
      setSelectedRowKeys([]);
    },
  });

  const setExportedMutation = useMutation(setExportedHandler, {
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries(["orders"]);
      setSelectedRowKeys([]);
    },
  });

  const columns = [
    {
      title: null,
      key: "exportedAt",
      dataType: "none",
      dataIndex: "exportedAt",
      render: (text, i) => {
        if (i.status === "approved") {
          return (
            <Checkbox
              onChange={(e) => {
                if (e.target.checked) {
                  setSelectedRowKeys([...selectedRowKeys, i.id]);
                } else {
                  setSelectedRowKeys(selectedRowKeys.filter((x) => x !== i.id));
                }
              }}
              checked={
                selectedRowKeys.includes(i.id) ||
                (selectedRowKeys.length === 1 &&
                  selectedRowKeys[0] === "all") ||
                (selectedRowKeys.length === 1 &&
                  !i.exported &&
                  selectedRowKeys[0] === "allAvailable")
              }
            />
          );
        } else {
          return <Checkbox disabled />;
        }
      },
      hidden: true,
    },
    {
      title: "Son Aktarma",
      key: "exportedAt",
      dataIndex: ["exported"],
      dataType: user.role === "Admin" ? "enum" : "none",
      options: [
        {
          label: "Dışarıya Aktarıldı",
          value: 1,
        },
        {
          label: "Aktarım Bekliyor",
          value: 0,
        },
      ],
      render: (text, i) => {
        if (i.status === "approved") {
          if (i.exportedAt) {
            return (
              <p className="flex items-center space-x-1">
                <BsFillCircleFill className=" text-blue-400" />{" "}
                <span>
                  {moment(i.exportedAt).format("DD MMMM YYYY | HH:mm")}
                </span>
              </p>
            );
          } else {
            return (
              <p className="flex items-center space-x-1">
                <BsFillCircleFill className=" text-gray-400" />{" "}
                <span>Henüz Aktarılmadı</span>
              </p>
            );
          }
        } else {
          return null;
        }
      },
      hidden: true,
    },
    {
      title: "Müşteri",
      dataIndex: ["Customer", "name"],
      key: "customer",
      dataType: "none",
    },
    {
      title: "Durum",
      dataIndex: ["status"],
      key: "customer",
      dataType: "enum",
      options: [
        {
          label: "Onaylandı",
          value: "approved",
        },
        {
          label: "Onay Bekliyor",
          value: "pending",
        },
        {
          label: "İptal Edildi",
          value: "rejected",
        },
      ],

      render: (status) => {
        return status === "pending" ? (
          <p className="flex items-center space-x-1">
            <BsFillCircleFill className=" text-yellow-400" />{" "}
            <span>Onay Bekliyor</span>
          </p>
        ) : status === "approved" ? (
          <p className="flex items-center space-x-1">
            <BsFillCircleFill className=" text-green-400" />{" "}
            <span>Onaylandı</span>
          </p>
        ) : (
          <p className="flex items-center space-x-1">
            <BsFillCircleFill className=" text-red-400" />{" "}
            <span>İptal Edildi</span>
          </p>
        );
      },
    },
    {
      title: "Tarih",
      dataIndex: "createdAt",
      key: "customer",
      dataType: "none",
      render: (text) => {
        return moment(text).format("DD MMMM YYYY | HH:mm");
      },
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      dataType: "float",
      render: (value) => formatPrice(value) + " ₺",
    },
    // {
    //   title: "Uyarılar",
    //   dataType: "none",
    //   render: () => <p>Düşük Öncelikli</p>,
    // },
    // {
    //   title: "İşlemler",
    //   dataType: "none",
    //   render: () => <Button>İncele</Button>,
    // },
  ];

  return (
    <>
      <DataTable
        columns={
          user.role !== "Admin"
            ? columns.filter((x) => x.key !== "exportedAt")
            : columns
        }
        export={false}
        table="orders"
        expandable={{
          expandedRowRender: (record) => (
            <div className="flex flex-col space-y-8 bg-slate-300 p-8">
              <div className="flex flex-row space-x-8 w-full">
                <div className="flex flex-col w-1/2 space-y-1">
                  <p className="font-bold">Müşteri Bilgileri</p>
                  <p>Müşteri Kodu: {record.Customer.code}</p>
                  <p>Müşteri Ünvanı: {record.Customer.name}</p>
                  <p>Müşteri Adresi: {record.Customer.address}</p>
                  <p>Müşteri Telefonu: {record.Customer.phone}</p>
                  <p>Müşteri Vergi Dairesi: {record.Customer.vergiD}</p>
                  <p>Müşteri Vergi No: {record.Customer.vergiNo}</p>
                </div>
                <div className="flex flex-col w-1/2 space-y-1">
                  <p className="font-bold">Sipariş Bilgileri</p>
                  <p>
                    Sipariş Şekli:{" "}
                    {record.salesType === "cash" ? "Nakit" : "Kredi Kartı"}
                  </p>
                  <p>
                    Sipariş Tarihi:{" "}
                    {moment(record.createdAt).format("DD MMMM YYYY | HH:mm")}
                  </p>
                  <p>Sipariş Notu: {record.note}</p>
                  <span className="h-4"></span>
                  <p>
                    <b>Satış Temsilcisi: {record.User?.name}</b>
                  </p>
                  {record.status === "approved" && (
                    <>
                      <p>
                        <b>Onaylayan: {record.Responser?.name}</b>
                      </p>
                      <p>
                        <b>Onay Notu:</b> {record.responseNote}
                      </p>
                    </>
                  )}
                  {record.status === "rejected" && (
                    <>
                      <p>
                        <b>İptal Eden: {record.Responser?.name}</b>
                      </p>
                      <p>
                        <b>İptal Notu:</b> {record.responseNote}
                      </p>
                    </>
                  )}
                </div>
                <div className="flex flex-col w-1/2 space-y-1">
                  <p className=" font-light p-2 mb-4 -mr-8 bg-red-400 text-lg">
                    Sipariş Id Numarası : {record.id}
                  </p>
                  <p className="font-bold">Fiyat Bilgileri</p>
                  <p>
                    Toplam Fiyat:{" "}
                    {record.discountType === "percent"
                      ? formatPrice(
                          (record.total / (100 - record.discount)) * 100,
                        )
                      : formatPrice(record.discount + record.total)}
                    ₺
                  </p>
                  <p>
                    İskonto:{" "}
                    {record.discountType === "percent"
                      ? "%" + record.discount
                      : record.discount + "₺"}
                  </p>
                  <p>
                    Indirim Miktarı:{" "}
                    {record.discountType === "percent"
                      ? formatPrice(
                          (record.total / (100 - record.discount)) * 100 -
                            record.total,
                        ) + "₺"
                      : formatPrice(record.discount) + "₺"}
                  </p>
                  <p>
                    <b>Satış Toplamı: {formatPrice(record.total)}₺</b>
                  </p>
                </div>
              </div>
              <div className="max-h-96 overflow-y-scroll overflow-x-hidden">
                <Table
                  pagination={false}
                  columns={[
                    {
                      title: "Marka",
                      dataIndex: ["Product", "brand"],
                      key: "brand",
                    },
                    {
                      title: "Stok Kodu",
                      dataIndex: ["Product", "code"],
                      key: "code",
                    },
                    {
                      title: "Ürün Kodu",
                      dataIndex: ["Product", "name"],
                      key: "kod",
                      render: (value, record) => {
                        return <p>{record.Product.name.split("-")[0]}</p>;
                      },
                    },
                    {
                      title: "Ürün",
                      dataIndex: ["Product", "name"],
                      key: "name",
                      render: (value, record) => {
                        return <p>{record.Product.name.split("-")[1]}</p>;
                      },
                    },
                    {
                      title: "Stok",
                      dataIndex: ["Product", "quantity"],
                      key: "stock",
                    },
                    {
                      title: "Miktar",
                      key: "quantity",
                      render: (record) =>
                        record.Product.quantity - record.quantity > 25 ? (
                          <p className="text-green-600 font-bold">
                            {record.quantity} adet
                          </p>
                        ) : record.Product.quantity - record.quantity > 10 ? (
                          <p className="text-yellow-600 font-bold">
                            {record.quantity} adet
                          </p>
                        ) : (
                          <p className="text-red-600 font-bold">
                            {record.quantity} adet
                          </p>
                        ),
                    },
                    {
                      title: "Birim Fiyat",
                      key: "unit",
                      render: (record) => formatPrice(record.price) + "₺",
                    },
                    // {
                    //   title: "İndirim",
                    //   key: "discount",
                    //   render: (record) =>
                    //     record.discountType === "percent"
                    //       ? record.discount + "%"
                    //       : record.discount + "₺",
                    // },
                    {
                      title: "Toplam Fiyat",
                      key: "price",
                      render: (record) =>
                        formatPrice(record.price * record.quantity) + "₺",
                    },
                  ]}
                  dataSource={[
                    ...record.OrderItems.map((item) => ({
                      ...item,
                      key: item.id,
                    })),
                  ]}
                />
              </div>
              {record.status === "pending" ||
              (user.role === "Admin" && record.status === "approved") ? (
                <OrderResponse
                  id={record.id}
                  reject={rejectMutation.mutate}
                  approve={approveMutation.mutate}
                  status={record.status}
                />
              ) : null}
            </div>
          ),
        }}
      >
        {user.role === "Admin" && (
          <div className="w-full">
            <div className="bg-white w-fit px-4 py-2 flex space-x-6 border">
              {/* <Checkbox
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedRowKeys(["all"]);
                  } else {
                    setSelectedRowKeys([]);
                  }
                }}
                checked={selectedRowKeys[0] === "all"}
              >
                Tümünü Seç
              </Checkbox> */}

              <Checkbox
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedRowKeys(["allAvailable"]);
                  } else {
                    setSelectedRowKeys([]);
                  }
                }}
                checked={selectedRowKeys[0] === "allAvailable"}
              >
                Tüm Aktarılmamışları Seç
              </Checkbox>

              <Button
                className="bg-blue-400"
                type="primary"
                size="small"
                onClick={() => exportMutation.mutate(selectedRowKeys)}
              >
                Seçilenleri Aktar
              </Button>
              <Button
                className="bg-green-400 hover:bg-green-500 text-white hover:text-white"
                size="small"
                onClick={() => setExportedMutation.mutate(selectedRowKeys)}
                disabled={
                  selectedRowKeys.length === 0 ||
                  !selectedRowKeys ||
                  selectedRowKeys[0] === "allAvailable" ||
                  selectedRowKeys[0] === "all"
                }
              >
                Aktarıldı Olarak İşaretle
              </Button>
            </div>
          </div>
        )}
      </DataTable>
    </>
  );
};
export default SalesTable;
