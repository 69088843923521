import { Button, message } from "antd";
import React from "react";
import SaleFormProduct from "./SaleFormProduct";

const SaleFormProducts = ({ setForm, form }) => {
  return (
    <>
      {form.products.map((item, index) => (
        <SaleFormProduct
          key={item.uid}
          onChange={(val) => {
            setForm((lastItem) => {
              const index = lastItem.products.findIndex(
                (item) => val.uid === item.uid,
              );

              return {
                ...form,
                products: [
                  ...lastItem.products.slice(0, index),
                  val,
                  ...lastItem.products.slice(index + 1),
                ],
              };
            });
          }}
          onDelete={(val) => {
            setForm((lastItem) => {
              return {
                ...form,
                products: lastItem.products.filter(
                  (item, i) => item.uid !== val,
                ),
              };
            });
          }}
          value={item}
        />
      ))}
      <Button
        type="dashed"
        className=" rounded-md"
        onClick={() => {
          if (
            form.products.filter((item) => item.product === undefined).length >
            0
          ) {
            message.warning({
              key: "err_01",
              content:
                "Yeni ürün ekleyebilmek için tüm ürün satırlarının dolu olması gerekmektedir.",
            });
          } else {
            setForm({
              ...form,
              products: [
                ...form.products,
                {
                  uid: Math.floor(100000 + Math.random() * 900000),
                  product: undefined,
                  discountType: "amount",
                  discount: 0,
                  quantity: 0,
                  totalPrice: 0,
                },
              ],
            });
          }
        }}
      >
        Ürün Ekle
      </Button>
    </>
  );
};

export default SaleFormProducts;
