import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import DashboardInfoBox from "../DashboardInfoBox";
import DashboardDifferentBox from "../DashboardDifferentBox";
import { useQuery } from "@tanstack/react-query";
import {
  countCustomers,
  countOrders,
  countProducts,
  outStock,
  pendingOrders,
} from "../../api";
import { Table } from "antd";
import { useNavigate } from "react-router-dom";
import { formatPrice } from "../../utils";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Genel Bakış",
    },
  },
};

const months = [
  "Ocak",
  "Şubat",
  "Mart",
  "Nisan",
  "Mayıs",
  "Haziran",
  "Temmuz",
  "Ağustos",
  "Eylül",
  "Ekim",
  "Kasım",
  "Aralık",
];

const currentTime = new Date().getMonth() + 1;

//last 6 months
const labels =
  currentTime >= 5
    ? [...months.slice(currentTime - 6, currentTime)]
    : [...months.slice(currentTime - 6), ...months.slice(0, currentTime)];

const PageDashboard = ({ user }) => {
  const navigate = useNavigate();

  // Queries
  const { isLoading: pendingOrdersLoading, data: pendingOrdersList } = useQuery(
    ["pendingOrders"],
    pendingOrders,
  );

  const { isLoading: outStockLoading, data: outStockList } = useQuery(
    ["outStock"],
    outStock,
  );

  const { isLoading: productCountLoading, data: productCount } = useQuery(
    ["productCount"],
    countProducts,
  );

  const { isLoading: customerCountLoading, data: customerCount } = useQuery(
    ["customerCount"],
    countCustomers,
  );

  const { isLoading: orderCountLoading, data: orderCount } = useQuery(
    ["orderCount"],
    countOrders,
  );

  const data = {
    labels,
    datasets: [
      {
        label: "Sipariş Sayısı",
        data: [
          orderCount?.stats?.summary?.filter((i) => {
            return (
              Number(i.month.split("-")[1]) ===
              months.findIndex((i) => i === labels[0]) + 1
            );
          })[0]?.count || 0,
          orderCount?.stats?.summary?.filter((i) => {
            return (
              Number(i.month.split("-")[1]) ===
              months.findIndex((i) => i === labels[1]) + 1
            );
          })[0]?.count || 0,
          orderCount?.stats?.summary?.filter((i) => {
            return (
              Number(i.month.split("-")[1]) ===
              months.findIndex((i) => i === labels[2]) + 1
            );
          })[0]?.count || 0,
          orderCount?.stats?.summary?.filter((i) => {
            return (
              Number(i.month.split("-")[1]) ===
              months.findIndex((i) => i === labels[3]) + 1
            );
          })[0]?.count || 0,
          orderCount?.stats?.summary?.filter((i) => {
            return (
              Number(i.month.split("-")[1]) ===
              months.findIndex((i) => i === labels[4]) + 1
            );
          })[0]?.count || 0,
          orderCount?.stats?.summary?.filter((i) => {
            return (
              Number(i.month.split("-")[1]) ===
              months.findIndex((i) => i === labels[5]) + 1
            );
          })[0]?.count || 0,
        ],
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Toplam Tutar (Bin TL)",
        data: [
          orderCount?.stats?.summary?.filter((i) => {
            return (
              Number(i.month.split("-")[1]) ===
              months.findIndex((i) => i === labels[0]) + 1
            );
          })[0]?.total / 1000 || 0,
          orderCount?.stats?.summary?.filter((i) => {
            return (
              Number(i.month.split("-")[1]) ===
              months.findIndex((i) => i === labels[1]) + 1
            );
          })[0]?.total / 1000 || 0,
          orderCount?.stats?.summary?.filter((i) => {
            return (
              Number(i.month.split("-")[1]) ===
              months.findIndex((i) => i === labels[2]) + 1
            );
          })[0]?.total / 1000 || 0,
          orderCount?.stats?.summary?.filter((i) => {
            return (
              Number(i.month.split("-")[1]) ===
              months.findIndex((i) => i === labels[3]) + 1
            );
          })[0]?.total / 1000 || 0,
          orderCount?.stats?.summary?.filter((i) => {
            return (
              Number(i.month.split("-")[1]) ===
              months.findIndex((i) => i === labels[4]) + 1
            );
          })[0]?.total / 1000 || 0,
          orderCount?.stats?.summary?.filter((i) => {
            return (
              Number(i.month.split("-")[1]) ===
              months.findIndex((i) => i === labels[5]) + 1
            );
          })[0]?.total / 1000 || 0,
        ],
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  if (user.role === "RD") {
    return (
      <div className="h-screen grid grid-cols-12 grid-rows-5 gap-4">
        <div className=" col-span-6 row-span-3 bg-white border rounded-md p-2">
          <Line options={options} data={data} />
        </div>
        <div className="col-span-6 row-span-4 bg-white border rounded-md p-2 flex flex-col space-y-2 items-center overflow-y-scroll">
          <span className="text-xs font-semibold text-gray-600">
            Onay Bekleyen Siparişler
          </span>
          <Table
            dataSource={pendingOrdersList?.items}
            pagination={false}
            loading={pendingOrdersLoading}
            className="w-full"
            columns={[
              {
                title: "Müşteri",
                dataIndex: ["Customer", "name"],
                key: "customer",
                render: (text, record) => (
                  <span
                    key={record.id}
                    className=" hover:text-blue-500 transition-all cursor-pointer"
                    onClick={() =>
                      navigate(`/sales?filter=[id:${record.id}:eq&limit=40]`)
                    }
                  >
                    {text}
                  </span>
                ),
              },
              {
                title: "Tutar",
                dataIndex: "total",
                key: "total",
              },
              {
                title: "Tarih",
                dataIndex: "createdAt",
                key: "createdAt",
                render: (value) => {
                  return new Date(value).toLocaleDateString();
                },
              },
            ]}
          />
        </div>
        <DashboardDifferentBox
          className="col-span-3 bg-white border rounded-md p-2"
          data={{
            title: "Sipariş Sayısı",
            sufix: "sipariş",
            loading: orderCountLoading,
            value: orderCount?.stats.count,
          }}
        />
        <DashboardDifferentBox
          className="col-span-3 bg-white border rounded-md p-2"
          data={{
            title: "Sipariş Tutarı",
            sufix: "₺",
            loading: orderCountLoading,
            value: orderCount?.stats.total,
            isPrice: true,
          }}
        />
      </div>
    );
  }

  return (
    <div className="h-screen grid grid-cols-12 grid-rows-5 gap-4">
      <div className=" col-span-6 row-span-2 bg-white border rounded-md p-2">
        <Line options={options} data={data} />
      </div>
      <DashboardDifferentBox
        className="col-span-3 bg-white border rounded-md p-2"
        data={{
          title: "Sipariş Sayısı",
          sufix: "sipariş",
          loading: orderCountLoading,
          value: orderCount?.stats.count,
        }}
      />
      <DashboardDifferentBox
        className="col-span-3 bg-white border rounded-md p-2"
        data={{
          title: "Sipariş Tutarı",
          sufix: "₺",
          loading: orderCountLoading,
          value: orderCount?.stats.total,
          isPrice: true,
        }}
      />
      <div className="col-span-6 row-span-3 bg-white border rounded-md p-2 flex flex-col space-y-2 items-center overflow-y-scroll">
        <span className="text-xs font-semibold text-gray-600">
          Stoku Azalan Ürünler
        </span>
        <Table
          dataSource={outStockList?.items}
          pagination={false}
          loading={outStockLoading}
          className="w-full"
          columns={[
            {
              title: "Ürün Adı",
              dataIndex: ["name"],
              key: "name",
              render: (text, record) => (
                <span
                  className=" hover:text-blue-500 transition-all cursor-pointer"
                  onClick={() =>
                    navigate(`/products?filter=[id:${record.id}:eq]`)
                  }
                >
                  {text}
                </span>
              ),
            },
            {
              title: "Toplam",
              dataIndex: "quantity",
              key: "quantity",
            },
            {
              title: "Rezerve",
              dataIndex: "reservedstock",
              key: "reservedstock",
            },
            {
              title: "Stok",
              dataIndex: "stock",
              key: "stock",
            },
          ]}
        />
      </div>
      <div className="col-span-6 row-span-2 bg-white border rounded-md p-2 flex flex-col space-y-2 items-center overflow-y-scroll">
        <span className="text-xs font-semibold text-gray-600">
          Onay Bekleyen Siparişler
        </span>
        <Table
          dataSource={pendingOrdersList?.items}
          pagination={false}
          loading={pendingOrdersLoading}
          className="w-full"
          columns={[
            {
              title: "Müşteri",
              dataIndex: ["Customer", "name"],
              key: "customer",
              render: (text, record) => (
                <span
                  className=" hover:text-blue-500 transition-all cursor-pointer"
                  onClick={() =>
                    navigate(`/sales?filter=[id:${record.id}:eq&limit=40]`)
                  }
                >
                  {text}
                </span>
              ),
            },
            {
              title: "Tutar",
              dataIndex: "total",
              key: "total",
            },
            {
              title: "Tarih",
              dataIndex: "createdAt",
              key: "createdAt",
              render: (value, record) => {
                return new Date(value).toLocaleDateString();
              },
            },
          ]}
        />
      </div>
      <DashboardInfoBox
        className="col-span-2 bg-white border rounded-md p-2"
        data={{
          title: "Toplam Cari",
          value: customerCount?.count,
          loading: customerCountLoading,
          postfix: "müşteri",
        }}
      />
      <DashboardInfoBox
        className="col-span-2 bg-white border rounded-md p-2"
        data={{
          title: "Ürün Çeşidi",
          value: productCount?.count,
          loading: productCountLoading,
          postfix: "ürün",
        }}
      />
      <DashboardInfoBox
        className="col-span-2 bg-white border rounded-md p-2"
        data={{
          title: "Toplam Sipariş",
          value: orderCount?.count,
          loading: orderCountLoading,
          postfix: "sipariş",
        }}
      />
      <DashboardInfoBox
        className="col-span-3 bg-white border rounded-md p-2"
        data={{
          title: "Toplam Ciro",
          value: formatPrice(orderCount?.total) + " ₺",
          loading: orderCountLoading,
        }}
      />
      <DashboardInfoBox
        className="col-span-3 bg-white border rounded-md p-2"
        data={{
          title: "Sepet Ortalaması",
          value: formatPrice(orderCount?.total / orderCount?.count) + " ₺",
          loading: orderCountLoading,
        }}
      />
    </div>
  );
};

export default PageDashboard;
