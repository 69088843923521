import React, { useState } from "react";
import { Input, Button, Form } from "antd";
import { getSettings, updateSettings } from "../../api";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

export const SettingsForm = () => {
  const queryClient = useQueryClient();

  // Queries
  const { isLoading, data } = useQuery(["settings"], getSettings);

  if (isLoading) return <div>Loading...</div>;

  return (
    <div className=" flex flex-col space-y-2">
      <h3 className=" text-lg">Genel Ayarlar</h3>
      {data?.map((setting) => {
        return (
          <SettingsInput
            key={setting.id}
            setting={setting}
            queryClient={queryClient}
          />
        );
      })}
    </div>
  );
};

const SettingsInput = ({ setting, queryClient }) => {
  const [loading, setLoading] = useState(false);
  return (
    <Form
      key={setting.id}
      layout="vertical"
      initialValues={{
        value: setting.value,
      }}
      onFinish={async (values) => {
        setLoading(true);
        await updateSettings(setting.key, values);
        await queryClient.invalidateQueries("settings");
        setLoading(false);
      }}
    >
      <div className="flex items-end">
        <Form.Item name="value" label={setting.title} className="flex-grow">
          <Input placeholder="boş olması sorunlara sebep olabilir" />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" disabled={loading}>
            Güncelle
          </Button>
        </Form.Item>
      </div>
    </Form>
  );
};
