import { Button, Input, Select, Tooltip } from "antd";
import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import fileDownload from "js-file-download";
import axios from "axios";
import moment from "moment";
import { utils, writeFile, readFile } from "xlsx";

const Option = Select.Option;

const endPoint =
  process.env.REACT_APP_API_URL || new URL(window.location.href).origin;

const ExportFile = (props) => {
  const { table } = props;
  const [searchParams] = useSearchParams();
  const [format, setFormat] = useState("xlsx");

  const filterString = searchParams.get("filter");

  const trName = (name) => {
    if (name === "customers") {
      return "Cariler";
    }
    if (name === "products") {
      return "Ürünler";
    }
    if (name === "users") {
      return "Kullanıcılar";
    }
  };

  const fileName = `SVS_${trName(table)}_Export_${moment().format(
    "DD-MM-YY",
  )}.${format}`;

  const exportHandler = async () => {
    const response = await axios(
      `${endPoint}/api/${table}?limit=10000${
        filterString?.length > 0 ? `&filter=${filterString}` : ""
      }`,
    );

    let data = response.data.items;

    if (format === "xls") {
      const arr = data.map((item) => {
        return Object.values(item).join(",");
      });
      arr.unshift(Object.keys(data[0]).join(","));
      data = arr.join("\n");
    }

    if (format === "xlsx") {
      const wb = utils.book_new();
      const ws = utils.json_to_sheet(data);
      utils.book_append_sheet(wb, ws, "Products");
      const wbout = writeFile(wb, `${fileName}`);
      readFile(wbout);
    }

    if (format === "csv") {
      const arr = data.map((item) => {
        return Object.values(item).join(",");
      });
      arr.unshift(Object.keys(data[0]).join(","));
      data = arr.join("\n");
      fileDownload(data, fileName, "text/csv;charset=utf-8");
    }

    if (format === "tsv") {
      const arr = data.map((item) => {
        return Object.values(item).join("\t");
      });
      arr.unshift(Object.keys(data[0]).join("\t"));
      data = arr.join("\n");
      fileDownload(data, fileName, "text/tab-separated-values;charset=utf-8");
    }

    if (format === "json") {
      data = JSON.stringify(data);
      fileDownload(data, fileName, "application/json;charset=utf-8");
    }
  };

  return (
    <Input.Group compact style={{ maxWidth: 250 }}>
      <Select
        defaultValue={format}
        style={{ width: 90 }}
        onChange={(val) => setFormat(val)}
      >
        <Option value="xlsx">Excel</Option>
        <Option value="csv">CSV</Option>
        <Option value="tsv">TSV</Option>
        <Option value="json">JSON</Option>
      </Select>
      <Tooltip title="dışarıya aktarma formatını soldaki kutucuktan seçebilirsiniz.">
        <Button type="primary" className=" bg-blue-400" onClick={exportHandler}>
          Dışa Aktar
        </Button>
      </Tooltip>
    </Input.Group>
  );
};

export default ExportFile;
