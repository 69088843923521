import { Button, Input, message, Popconfirm } from "antd";
import React from "react";
import { BiPlus } from "react-icons/bi";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { addRegions, getRegions, removeRegion, updateRegion } from "../../api";
import { useState } from "react";

const Region = (props) => {
  const { onSave, onDelete, value } = props;
  const [name, setName] = useState(value.name);
  const [regions, setRegions] = useState(value.regions?.join(","));

  return (
    <div className=" bg-slate-200 col-span-3 flex flex-col space-y-2 p-2">
      <Input
        placeholder="Bölge İsmi"
        value={name}
        onChange={(e) => {
          setName(e.target.value);
        }}
      />
      <Input.TextArea
        rows={3}
        placeholder="Bölgeler"
        value={regions}
        onChange={(e) => {
          setRegions(e.target.value);
        }}
      />
      <div className="flex space-x-2">
        <Button
          className=" bg-blue-400"
          type="primary"
          block
          onClick={() =>
            onSave({
              id: value.id,
              values: {
                name: name,
                regions: regions,
              },
            })
          }
        >
          Kaydet
        </Button>
        <Popconfirm
          title="Bir bölgeyi sildiğinizde bu bölgeye bağlı tüm kullanıcılar bölgesiz kalacaktır. Emin misiniz?"
          onConfirm={() => onDelete(value.id)}
          onCancel={() => {}}
          okText="Onayla"
          cancelText="İptal"
        >
          <Button type="primary" block danger>
            Sil
          </Button>
        </Popconfirm>
      </div>
    </div>
  );
};

export const Regions = () => {
  // Access the client
  const queryClient = useQueryClient();

  // Queries
  const { isLoading, data } = useQuery(["regions"], getRegions);

  // Mutations
  const createMutation = useMutation(addRegions, {
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries(["regions"]);
    },
  });

  const deleteMutation = useMutation(removeRegion, {
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries(["regions"]);
    },
  });

  const updateMutation = useMutation(updateRegion, {
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries(["regions"]);
      message.success("Bölge Güncellendi");
    },
  });

  if (isLoading) {
    return <p>loading</p>;
  }

  return (
    <div className=" flex flex-col space-y-2">
      <h3 className=" text-lg">Bölgeler</h3>
      <div className="grid grid-cols-12 gap-4">
        {data.items?.map((region) => {
          return (
            <Region
              onDelete={() => {
                deleteMutation.mutate(region.id);
              }}
              onSave={(val) => {
                updateMutation.mutate(val);
              }}
              value={region}
              key={region.id}
            />
          );
        })}

        <div
          className="col-span-3 flex items-center justify-center border-dashed border-4 text-slate-400 border-slate-400 hover:text-blue-200 hover:border-blue-200 cursor-pointer transition-all h-44"
          onClick={() => {
            createMutation.mutate({
              name: "Yeni Bölge",
            });
          }}
        >
          <BiPlus className="text-4xl" />
        </div>
      </div>
    </div>
  );
};
