import React from "react";
import BreadCrumb from "../BreadCrumb";
import { Regions } from "../forms/Regions";
import { SettingsForm } from "../forms/SettingsForm";
import { EmailTemplates } from "../forms/EmailTemplates";

const PageSettings = ({ userRole }) => {
  return (
    <>
      <BreadCrumb breadcrumb={[{ title: "App" }, { title: "Ayarlar" }]} />
      <div className="bg-white p-4 mb-4">Settings</div>
      <div className="bg-white p-4 mb-4">
        <Regions />
      </div>
      {userRole === "Admin" && (
        <>
          <div className="bg-white p-4 mb-4">
            <SettingsForm />
          </div>
          <div className="bg-white p-4 mb-4">
            <EmailTemplates />
          </div>
        </>
      )}
    </>
  );
};

export default PageSettings;
