import React from "react";
import BreadCrumb from "../BreadCrumb";
import SaleForm from "../SaleForm";

export const PageSalesCreate = () => {
  return (
    <>
      <BreadCrumb
        breadcrumb={[
          { title: "App" },
          { title: "Satışlar", href: "/sales" },
          { title: "Satış Oluştur" },
        ]}
      />
      <div className="bg-white p-4">
        <SaleForm />
      </div>
    </>
  );
};
