import { Select } from "antd";
import React, { useState, useEffect } from "react";
import axios from "axios";

const endPoint =
  process.env.REACT_APP_API_URL || new URL(window.location.href).origin;

const { Option } = Select;

const fetch = (table, col, val, op, callback) => {
  axios
    .get(`${endPoint}/api/${table}?filter=[${col}:${val}:${op}]`)
    .then((response) => {
      callback(response.data.items);
    })
    .catch((error) => {
      console.log(error);
    });
};

const SearchInput = (props) => {
  const [data, setData] = useState([]);
  const [val, setVal] = useState(null);
  const { onChange, value } = props;

  useEffect(() => {
    onChange(data.filter((item) => item.id === parseInt(val))[0]);
  }, [val]);

  const handleSearch = (val) => {
    if (val) {
      fetch(props.table, props.col, val, props.op, setData);
    } else {
      setData([]);
    }
  };

  const handleChange = (val) => {
    setVal(val);
  };

  const options = data.map((d) => <Option key={d.id}>{d.name}</Option>);

  return (
    <Select
      showSearch
      value={value?.name}
      placeholder="input search text"
      defaultActiveFirstOption={false}
      showArrow={false}
      filterOption={false}
      onSearch={handleSearch}
      onChange={handleChange}
      notFoundContent={null}
      className={props.className}
      style={props.style}
    >
      {options}
    </Select>
  );
};

export default SearchInput;
