import React from "react";
import DataTable from "../DataTable";

const columns = [
  {
    title: "Bölge",
    dataIndex: "region",
    key: "region",
    dataType: "integer",
  },
  {
    title: "Kod",
    dataIndex: "code",
    key: "code",
    dataType: "string",
  },
  {
    title: "İsim",
    dataIndex: "name",
    key: "name",
    dataType: "string",
  },
  {
    title: "İlçe",
    dataIndex: "ilce",
    key: "ilce",
    dataType: "string",
  },
  {
    title: "Adres",
    dataIndex: "address",
    key: "address",
    dataType: "string",
  },
  {
    title: "M.Temsilcisi",
    key: "salesman",
    dataType: "string",
    dataIndex: "salesman",
    render: (text, record) => {
      return (
        <>
          {record.salesman.map((i) => {
            return <p>{i}</p>;
          })}
        </>
      );
    },
  },
];

const CustomerTable = () => {
  return <DataTable columns={columns} table="customers" />;
};
export default CustomerTable;
