import React, { useState } from "react";
import { Input, Button, Form } from "antd";
import { getTemps, updateTemps } from "../../api";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

export const EmailTemplates = () => {
  const queryClient = useQueryClient();

  // Queries
  const { isLoading, data } = useQuery(["temps"], getTemps);

  if (isLoading) return <div>Loading...</div>;

  return (
    <div className=" flex flex-col space-y-2">
      <h3 className=" text-lg">Email Templates</h3>
      {data?.map((temp) => {
        return (
          <SettingsInput key={temp.id} temp={temp} queryClient={queryClient} />
        );
      })}
    </div>
  );
};

const SettingsInput = ({ temp, queryClient }) => {
  const [loading, setLoading] = useState(false);
  return (
    <Form
      key={temp.id}
      layout="vertical"
      initialValues={{
        title: temp.title,
        content: temp.content,
      }}
      onFinish={async (values) => {
        setLoading(true);
        await updateTemps(temp.key, values);
        await queryClient.invalidateQueries("temps");
        setLoading(false);
      }}
      className=" bg-gray-200 p-4"
    >
      <div className="flex flex-col">
        <h4 className=" text-lg mb-4">{temp.key} Template</h4>
        <Form.Item name="title" label={"Mail Başlığı"} className="flex-grow">
          <Input placeholder="boş olması sorunlara sebep olabilir" />
        </Form.Item>

        <Form.Item name="content" label={"Mail İçeriği"} className="flex-grow">
          <Input.TextArea
            rows={10}
            placeholder="boş olması sorunlara sebep olabilir"
          />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" disabled={loading}>
            Güncelle
          </Button>
        </Form.Item>
      </div>
    </Form>
  );
};
