import React from "react";
import LoginForm from "../forms/LoginForm";
import { useAuth } from "../../contexts/AuthContext";

const PageLogin = () => {
  const { user, loggedIn, login, loading } = useAuth();
  if (!loading) {
    if (loggedIn) {
    }
  }
  return (
    <div className=" bg-slate-800 w-screen h-screen flex justify-center items-center">
      <div className=" absolute top-4 left-8 text-white text-2xl font-semibold">
        SVS <span className=" font-light">Sales Management System</span>
      </div>
      <LoginForm userLogin={login} />
    </div>
  );
};

export default PageLogin;
