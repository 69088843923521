import React from "react";
import BreadCrumb from "../BreadCrumb";
import ImportForm from "../ImportForm";

export const PageProductImport = () => {
  return (
    <>
      <BreadCrumb
        breadcrumb={[
          { title: "App" },
          { title: "Ürünler", href: "/products" },
          { title: "Ürünleri İçe Aktar" },
        ]}
      />
      <ImportForm table="products" />
    </>
  );
};
