import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { Table, Pagination, Button, Input, Tooltip, Select } from "antd";
import { FrownOutlined } from "@ant-design/icons";

import axios from "axios";

import { useQuery } from "@tanstack/react-query";
import FilterGroup from "./FilterGroup";
import ExportFile from "./ExportFile";
import { fetchData } from "../api";

const Option = Select.Option;

const filterStringToFilters = (filterString) => {
  const filters = [];
  const filterArray = filterString.slice(1, filterString.length - 1).split(";");
  filterArray.forEach((filter) => {
    const [key, value, op] = filter.split(":");
    filters.push({ key, value, op });
  });
  return filters;
};

const DataTable = (props) => {
  const exportEvent = typeof props.export === "undefined" ? true : props.export;

  const [searchParams, setSearchParams] = useSearchParams();
  const [filters, setFilters] = React.useState(
    searchParams.get("filter")
      ? filterStringToFilters(searchParams.get("filter"))
      : [],
  );
  const [filterString, setFilterstring] = React.useState("");
  const [page, setPage] = React.useState(1);
  const [pager, setPager] = React.useState({
    total: 1,
    current: 1,
    pageSize: 20,
    count: 0,
  });

  useEffect(() => {
    const filterQuery = filters.map((filter) => {
      return `${filter.key}:${filter.value}:${filter.op}`;
    });

    const filterString = filterQuery.join(";");
    if (filterString.length > 0) {
      //setSearchParams({ filter: `[${filterString}]` });
      searchParams.set("filter", `[${filterString}]`);
      setSearchParams(searchParams);
    } else {
      searchParams.delete("filter");
      setSearchParams(searchParams);
    }

    setFilterstring(filterString);
    setPage(1);
  }, [filters, searchParams, setSearchParams]);

  // Queries
  const { isLoading, isError, data, error } = useQuery(
    [props.table, page, pager.pageSize, filterString],
    async () => {
      const data = await fetchData(props.table, pager, page, filterString);
      setPager({
        total: data.total,
        current: page,
        pageSize: data.limit,
        count: data.count,
      });
      return [...data.items.map((i) => ({ ...i, key: i.id }))];
    },
  );

  return (
    <div className=" flex flex-col items-end space-y-4">
      <FilterGroup
        columns={props.columns}
        table={props.table}
        filters={filters}
        onSubmit={(val) => {
          const arr = [];
          Object.keys(val).forEach((key) => {
            if (val[key].isActive && val[key].value !== "") {
              arr.push({
                key,
                value: val[key].value,
                op: val[key].op,
              });
            }
          });
          setFilters(arr);
        }}
      />
      {props.children}
      <Table
        className="w-full"
        columns={props.columns}
        dataSource={data}
        loading={isLoading}
        rowSelection={props.rowSelection}
        {...props.expandable}
        locale={{
          emptyText: isError ? (
            <div className="my-16">
              <FrownOutlined style={{ fontSize: 48, marginBottom: 10 }} />{" "}
              <br />
              <p>{error.response.data}</p>
            </div>
          ) : (
            <div className="my-16">
              <FrownOutlined style={{ fontSize: 48, marginBottom: 10 }} />{" "}
              <br />
              <p>Ürün Bulunamadı</p>
            </div>
          ),
          emptyIcon: <FrownOutlined />,
        }}
        pagination={false}
      />
      {!isLoading && (
        <div className="w-full flex justify-between">
          {exportEvent ? <ExportFile table={props.table} /> : <span></span>}
          <Pagination
            showQuickJumper
            defaultCurrent={1}
            total={pager.count}
            onChange={(n) => setPage(n)}
            current={pager.current}
            pageSize={pager.pageSize}
            onShowSizeChange={(n, s) => setPager({ ...pager, pageSize: s })}
          />
        </div>
      )}
    </div>
  );
};
export default DataTable;
