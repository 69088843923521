import { Modal, Button, Checkbox, Form, Input, Radio } from "antd";
import React from "react";
import RegionSelect from "./RegionSelect";

const CreateUser = (props) => {
  const { loading, visible, onFinish, onFinishFailed, onCancel, form } = props;

  const options = [
    { label: "LG", value: "LG" },
    { label: "Tefal", value: "Tefal" },
    { label: "Karcher", value: "Karcher" },
  ];

  return (
    <Modal
      title="Kullanıcı Ekle"
      open={visible}
      onCancel={onCancel}
      footer={null}
      header={null}
    >
      <Form
        name="basic"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout="vertical"
        form={form}
      >
        <Form.Item
          label="E-Posta"
          name="email"
          rules={[
            {
              required: true,
              message: "Lütfen e-posta adresi giriniz!",
              validator: (rule, value) => {
                if (!value) {
                  return Promise.reject("Lütfen e-posta adresi giriniz!");
                }
                if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
                  return Promise.reject(
                    "Lütfen geçerli bir e-posta adresi giriniz!",
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="İsim Soyisim"
          name="name"
          rules={[
            {
              required: true,
              message: "Lütfen ismim giriniz!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Rol"
          name="role"
          rules={[
            {
              required: true,
              message: "Lütfen rol seçiniz!",
            },
          ]}
        >
          <Radio.Group
            buttonStyle="solid"
            style={{
              marginTop: 16,
            }}
          >
            <Radio.Button value="Admin">Admin</Radio.Button>
            <Radio.Button value="SD">Satış Müdürü</Radio.Button>
            <Radio.Button value="RD">Bölge Müdürü</Radio.Button>
            <Radio.Button value="Salesman">Satış Temsilcisi</Radio.Button>
          </Radio.Group>
        </Form.Item>

        <Form.Item label="Marka" name="brands">
          <Checkbox.Group options={options} />
        </Form.Item>

        <div className="flex space-x-3">
          <Form.Item label="Kod" name="code" className="w-1/4">
            <Input />
          </Form.Item>
          <Form.Item label="Bölge" name="region" className="w-3/4">
            <RegionSelect />
          </Form.Item>
        </div>

        <Form.Item name="submit">
          <Button
            type="primary"
            htmlType="submit"
            block
            className="bg-blue-400"
            loading={loading}
          >
            Kaydet
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateUser;
