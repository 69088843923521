import { Breadcrumb } from "antd";
import React from "react";

const BreadCrumb = ({ breadcrumb }) => (
  <Breadcrumb className="mb-3">
    {breadcrumb.map((item, index) =>
      item.href ? (
        <Breadcrumb.Item key={index} href={item.href}>
          {item.title}
        </Breadcrumb.Item>
      ) : (
        <Breadcrumb.Item key={index}>{item.title}</Breadcrumb.Item>
      ),
    )}
  </Breadcrumb>
);

export default BreadCrumb;
